import React from "react";

const educationContent = [
  {
    year: "2022",
    degree: "MBA",
    institute: "UNIVERSITY OF TENNESSEE",
    details: `Advanced degree in business administration from world-class program focused in supply chain, accounting, finance, marketing, managment strategy and economics`,
  },
  {
    year: "2022",
    degree: "M.S. BUSINESS ANALYTICS",
    institute: "UNIVERSITY OF TENNESSEE",
    details: `Demonstrated mastery of coursework in neural networks, reinforcement learning, machine learning, forecasting, optimization, and data visualization`,
  },
  {
    year: "2018",
    degree: "B.S. APPLIED STATISTICS & ANALYTICS",
    institute: "BYU",
    details: `Main studies in applied statistics with special focus on the intersection of analytics with business. Also recieved considerable credits in the Spanish language`,
  },
];

const Education = () => {
  return (
    <ul>
      {educationContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.degree}
            <span className="place open-sans-font">{val.institute}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Education;
