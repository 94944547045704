import React from "react";

const experienceContent = [
  {
    year: "   2018 - Present",
    position: "Driector of Business Analytics",
    compnayName: "JTV",
    details: `Leading a team with proven record of driving profitiabilty and customer growth by harnessing the power of data analytics`,
  },
  {
    year: "2017 - 2018",
    position: "CONSULTANT",
    compnayName: "CLEARVIEW",
    details: `Pioneer in an exciting SaaS start-up. Responsible for guiding client leadership to best data practices and analytical insights`,
  },
  {
    year: "2016 - 2017",
    position: "DIRECT SALES",
    compnayName: "SAFEHOME ADT",
    details: `Developed craft of relationship building, persuasion, and leadership while personally generating $65,000+/month in revenue through door-to-door cold contact selling`,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
